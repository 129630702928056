import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import { Link } from 'gatsby-theme-material-ui';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';





  
const useStyles = makeStyles (() => ({
    cardStyle: {
        marginBottom: "20px",
        boxShadow: '5px 5px 20px #e0e0e0',
    },
}));

const Card1 = props => {
    const classes = useStyles ();
    const { title, customer, description, moreLink} = props; 
    return (
    <Card className={classes.cardStyle}>
      <CardActionArea button component={Link} to={moreLink} target="_blank" style={{textDecoration: "none"}}>
        <CardHeader
        avatar={
            <Avatar aria-label="recipe">
              <img src="https://www.southside-interactive.com/wp-content/uploads/2021/03/phone_120.png" style={{width: "40px"}}/>
            </Avatar>
          }
        
        title={
          <Typography variant="h5">
            {title}
          </Typography>
        }
        subheader={customer}
        action={
          <IconButton>
            <OpenInNewIcon />
          </IconButton>
        }
      />
      <Divider />
      <CardContent>
        <Typography>
        {description}
        </Typography>
      </CardContent>
      </CardActionArea>
    </Card>
    )
};

export default Card1;

