import { makeStyles, Typography } from '@material-ui/core';
import React from 'react'
import Container from '@material-ui/core/Container';

const useStyles = makeStyles (() => ({
    typographyStyles: {
        flex: 1
    },
    centerText: {
        textAlign: "center", 
        fontSize: "1.5em",
        margin: "10px 0 20px 0",
        fontStyle: "italic",
    }
}));

const PageIntro = props => {
    const { content} = props;
    const classes = useStyles ();
    return (
        <Typography className={classes.centerText}>
            {content}
        </Typography>
    );
};

export default PageIntro;

